import { client } from '../lib/request';
import { ConnectorConfiguration } from '../types';

export const getInstalledConnectors = async () => {
  const response = await client<Error, { data: ConnectorConfiguration[] }>({
    url: 'configurations',
    method: 'GET',
  });
  return response.data;
};
