import { StatusCodes } from 'http-status-codes';

/** Repository of application constants used in the app */
export const SESSION_STORAGE_KEYS = {
  ACCESS_TOKEN: 'accessToken',
  V1_TENANT_ID: 'account',
  V2_TENANT_ID: 'accountId',
};

export const TENANT_ID_HEADER = 'x-fabric-tenant-id';
export const UNLEASH_APP_NAME = 'mfa-integrations';
export const DEVELOPER_PORTAL_URL = 'https://developer.fabric.inc/docs/webhooks-adding-webhook';

export const BTN_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  DESTRUCTIVE: 'destructive',
} as any;

export const ICONS = {
  ARROW_DOWN: 'ArrowDown',
  LIGHTING_ON: 'LightingOn',
  TEST: 'Test',
  PENCIL: 'Pencil',
  TRASH: 'Trash',
};

export const DELIVERY_LOGS_SEARCH_PLACEHOLDER = 'Search by event type';

export const PAGINATION_DETAILS = {
  PER_PAGE: 10,
};

export const SEARCH_DEBOUNCE_TIME = 300;

export const EVENT_VIEWER_ROLE_PERMISSIONS = [
  'events:v3:application:read',
  'events:v3:dashboard:read',
  'events:v3:subscription:read',
  'events:v3:events:read',
  'events:v3:logs:read',
];

export const EVENT_ADMIN_ROLE_PERMISSIONS = [
  'events:v3:subscription:create',
  'events:v3:subscription:update',
  'events:v3:subscription:delete',
  'events:v3:events:test',
  'events:v3:events:resend',
  'events:v3:events:replay',
  'events:v3:events:bulk:resend',
];

export const LOCAL_STORAGE_KEYS = {
  USER_PERM: 'userperm',
  IS_LOGGED_IN_V2: 'isLoggedInWithIdV2',
};

// Note the use className 'show-on-row-hover', it will show/hide icons on table row hover.
export const SHOW_ON_ROW_HOVER_CLASS_NAME = 'show-on-row-hover';

// Sort options
export enum SORT_TYPE {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
  NULL = 'null',
}

export const API_PATHS = {
  SUBSCRIPTIONS: '/event-subscriptions',
};

export const TABLE_COL_WIDTH = {
  XS: 20,
  SM: 100,
  MD: 150,
  LG: 200,
};

export const SUCCESS_STATUS_CODES_OPTIONS = [
  StatusCodes.OK.toString(),
  StatusCodes.CREATED.toString(),
  StatusCodes.ACCEPTED.toString(),
  StatusCodes.NO_CONTENT.toString()
]

export const FAILED_STATUS_CODES_OPTIONS = [
  StatusCodes.BAD_REQUEST.toString(),
  StatusCodes.UNAUTHORIZED.toString(),
  StatusCodes.FORBIDDEN.toString(),
  StatusCodes.NOT_FOUND.toString(),
  StatusCodes.METHOD_NOT_ALLOWED.toString(),
  StatusCodes.REQUEST_TOO_LONG.toString(),
  StatusCodes.TOO_MANY_REQUESTS.toString(),
  StatusCodes.INTERNAL_SERVER_ERROR.toString(),
  StatusCodes.BAD_GATEWAY.toString(),
  StatusCodes.SERVICE_UNAVAILABLE.toString(),
  StatusCodes.GATEWAY_TIMEOUT.toString(),
];

export const STATUS_CODES_OPTIONS = [
  ...SUCCESS_STATUS_CODES_OPTIONS,
  ...FAILED_STATUS_CODES_OPTIONS,
];

export const SUBSCRIPTION_NAMES_LIMIT = 1000; // Fetch the first 1000 subscription names

export const MAX_SUBSCRIPTION_NAME_LENGTH = 50;

// Error codes returned from our SSE backend
export const SELF_SERVICE_BACKEND_ERRORS = {
  INVALID_TENANT_PROVIDED: 'INVALID_TENANT_PROVIDED',
};

// Error codes returned from Axios client
export const AXIOS_ERRORS = {
  ERR_NETWORK: 'ERR_NETWORK',
  ERR_BAD_REQUEST: 'ERR_BAD_REQUEST',
};

export const DEFAULT_LOG_RETENTION_IN_DAYS = 7;
export const SPECIAL_PRODUCTS = {
  ORDERS_PRODUCT: 'oms',
  INVENTORY_SERVICE: 'Inventory',
  SEARCH: 'search'
};


export const SHOPIFY_HELP_URL =
'https://d1clzym9wvjgs5.cloudfront.net/fabricMarketplace_ShopifyAppSetup.pdf';

export const SHOPIFY_KEY_MIN_LENGTH = 32;