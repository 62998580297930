import {
  Box,
  Filter,
  FilterSidebar,
  FilterSideBarVariant,
  MenuItem,
  MenuList,
  PageHeader,
  Search,
  SearchVariant,
  SelectableCard,
  Skeleton,
  theme,
  SkeletonH5,
} from 'ds4-beta';
import { memo, useCallback, useMemo, useState } from 'react';
import { DICTIONARY } from '../dictionary';
import { useConnectors } from './queries';
import { useNavigate } from 'react-router';

import './style.css';
import { Category, CombinedConnector } from '../types';
import { DEFAULT_CATEGORIES, DEFAULT_VISIBLE_PER_CATEGORY } from './constants';
import CollapsiblePanel from '../common/CollapsiblePanel';
import TrackstarCard from './components/TrackstarCard';

const ConnectorsList = () => {
  const [query, setQuery] = useState<string | null>(null);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const { isLoading, data: connectors } = useConnectors();
  const navigate = useNavigate();

  const addCategory = (category: Category) => {
    setSelectedCategories(prev => [...prev, category]);
  };

  const removeCategory = (category: Category) => {
    setSelectedCategories(prev =>
      prev.filter(c => c.label.toLowerCase() !== category.label.toLowerCase())
    );
  };

  const handleCategorySelect = (id: string, _: boolean, category: Category) => {
    const isSelected = selectedCategories.some(
      c => c.label.toLowerCase() === category.label.toLowerCase()
    );
    if (!isSelected) {
      addCategory(category);
    } else {
      removeCategory(category);
    }
  };

  const handleResetAll = () => {
    setSelectedCategories([]);
  };

  const categories = DEFAULT_CATEGORIES.map((category, index) => ({
    id: index.toString(),
    label: category,
    expanded: false,
    children: null,
    selected: selectedCategories.some(
      c => c.label.toLowerCase() === category.toLowerCase()
    ),
  }));

  const filteredConnectors = useMemo(
    () =>
      connectors?.filter(
        connector =>
          connector.name.toLowerCase().includes(query?.toLowerCase() ?? '') &&
          (selectedCategories.some(
            c => c.label.toLowerCase() === connector.domain.toLowerCase()
          ) ||
            selectedCategories.length === 0)
      ),
    [connectors, query, selectedCategories]
  );

  const groupedConnectors = useMemo(
    () =>
      filteredConnectors?.reduce<Record<string, any[]>>((groups, connector) => {
        const category = connector?.domain || 'Uncategorized';
        if (!groups[category]) {
          groups[category] = [];
        }
        groups[category].push(connector);
        return groups;
      }, {}),
    [filteredConnectors]
  );

  const handleOpenConnector = useCallback(connector => {
    navigate(`/integrations/${connector.name}`);
  }, []);

  const renderCard = useCallback((connector: CombinedConnector) => {
    return connector.trackstarId ? (
      <TrackstarCard {...connector} />
    ) : (
      <SelectableCard
        onChange={() => handleOpenConnector(connector)}
        key={connector.id}
        text={connector.name}
        description={connector.description}
        imageUrl={connector.imageUrl}
        orientation='horizontal'
        selected={false}
        assetType='image'
        width='100%'
      />
    );
  }, []);

  return (
    <Box padding={[6]} id='connectors-list'>
      <PageHeader
        h1Text={DICTIONARY.INTEGRATIONS_HEADER.TITLE_HEADER}
        h2Text={DICTIONARY.INTEGRATIONS_HEADER.MORE_INFO_HEADER}
      />

      <Box margin={{ top: 5 }}>
        <Search
          showResultOnFocus={false}
          placeholder={DICTIONARY.INTEGRATIONS_HEADER.SEARCH_PLACEHOLDER}
          width='33%'
          variant={SearchVariant.FIXED_GLOBAL}
          onChange={setQuery}
          value={query}
          onClear={() => setQuery(null)}
          results={
            <MenuList>
              {filteredConnectors?.map(({ name, id }) => (
                <MenuItem
                  key={id}
                  onClick={() => setQuery(name)}
                  style={{ width: '90%', color: 'black' }}
                >
                  {name}
                </MenuItem>
              ))}
            </MenuList>
          }
        />
      </Box>

      <Box margin={{ top: 3 }}>
        <Filter
          label={DICTIONARY.FILTER.CATEGORY}
          variant='primary'
          renderResults={
            <FilterSidebar
              checkboxTree={categories}
              onChange={handleCategorySelect}
              footer={{
                label: '',
                linkText: DICTIONARY.FILTER.RESET_ALL,
                onLinkClick: handleResetAll,
              }}
              variant={FilterSideBarVariant.SECONDARY}
            />
          }
        />
      </Box>

      {groupedConnectors &&
        Object.keys(groupedConnectors).map(category => {
          const items = groupedConnectors[category];

          const headerElement = isLoading ? (
            <SkeletonH5 width='100px' />
          ) : (
            <h3 style={theme.typography.body1.medium}>{category}</h3>
          );

          return (
            <CollapsiblePanel
              key={category}
              id={category}
              header={headerElement}
              items={items}
              isLoading={isLoading}
              defaultVisible={DEFAULT_VISIBLE_PER_CATEGORY}
              renderSkeleton={index => (
                <Skeleton key={index} height='88px' width='100%' />
              )}
              renderItem={renderCard}
            />
          );
        })}
    </Box>
  );
};

export default memo(ConnectorsList);
