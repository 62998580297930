import axios from 'axios';
import zlib from 'react-zlib-js';
import { Buffer } from 'buffer';
import { TENANT_ID_HEADER } from '../constants';
import { SessionUtils } from './session';

// 4kb limit to start compressing
const BIG_PAYLOAD_SIZE = 4092;

const client = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}`,
  headers: {
    'Authorization': `Bearer ${SessionUtils.getAccessToken()}`,
    'Content-Type': 'application/json',
    [TENANT_ID_HEADER]: SessionUtils.getTenantId(),
  },
});

client.interceptors.request.use(
  async config => {
    if (config.data && ['put', 'post'].includes(config.method)) {
      const bufferData = Buffer.from(JSON.stringify(config.data));

      if (bufferData.byteLength > BIG_PAYLOAD_SIZE) {
        config.data = await zip(bufferData);
        config.headers['Content-Encoding'] = 'gzip';
      }
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const zip = (data: Buffer): Promise<Buffer> => {
  return new Promise((resolve, reject) => {
    zlib.gzip(data, (error, result) => {
      if (error) reject(error);
      else resolve(result);
    });
  });
};

// Register request latency (used for testing subscriptions)
client.interceptors.request.use(config => {
  config.headers['request-start-time'] = Date.now();
  return config;
});

client.interceptors.response.use(
  response => {
    response.headers['duration'] = calculateRequestDuration(response);
    return response;
  },
  // Retrieved from copilot-mfa-communication -> ideally, this should be provided as a library from copilot
  async error => {
    // ESS returns 401, but other services return 403 for this scenario
    if (error && error.response && [401, 403].includes(error.response.status)) {
      let data;
      try {
        ({ data } = await axios.post('/api-identity/auth/local/refresh', {
          refreshToken: localStorage.getItem('refreshToken'),
        }));
      } catch (e) {
        window.location.assign(`${window.location.origin}/auth/login`);
        return null;
      }

      if (!data.accessToken || !data.refreshToken) {
        window.location.assign(`${window.location.origin}/auth/login`);
        return null;
      }

      const { accessToken, refreshToken } = data;
      sessionStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      const request = {
        ...error.config,
        headers: {
          ...error.config.headers,
          Authorization: accessToken,
        },
      };
      // Use different axios instance here so we don't infinite loop on 403 retries
      return axios.request(request);
    }

    if (error?.response?.headers) {
      error.response.headers['duration'] = calculateRequestDuration(
        error.response
      );
    }

    return Promise.reject(error);
  }
);

const calculateRequestDuration = response => {
  const start = response.config.headers['request-start-time'];
  const end = Date.now();
  return end - start;
};

export { client };
