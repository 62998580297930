import { Box, PageHeader } from 'ds4-beta';
import { useNavigate } from 'react-router-dom';
import { DICTIONARY } from '../../dictionary';

import './style.css';
import ShopifyPermissions from './components/ShopifyPermissions';
import ShopifyInstallationForm from './components/ShopifyInstallationForm';
import { useShopifyInstalledConnector } from './queries';
import ShopifySkeleton from './components/ShopifySkeleton';
import ShopifyStore from './components/ShopifyStore';
import { PageHeaderButtonProps } from 'ds4-beta/dist/components/pageheader';
import { useRef, useState } from 'react';

const ShopifyInstallation = () => {
  const navigate = useNavigate();
  const { data: shopify, isLoading } = useShopifyInstalledConnector();

  const formRef = useRef(null);

  const isInstalled = shopify != null;

  const primaryButtons: PageHeaderButtonProps[] = [];

  const [formState, setFormState] = useState({
    isDirty: false,
    isLoading: false,
    isSubmitSuccessful: false,
  });

  const onFormStateChange = (formState) => {
    setFormState(formState);
  };

  primaryButtons.push({
    onClick: () => navigate(-1),
    text: DICTIONARY.SHOPIFY.PAGE_HEADER.CLOSE_BUTTON_TEXT,
    variant: isInstalled ? 'tertiary' : 'primary',
  });

  if (isInstalled) {
    primaryButtons.push({
      dataTestid: DICTIONARY.SHOPIFY.TEST_IDS.SAVE_BUTTON,
      onClick: () => formRef.current?.submit(),
      text: DICTIONARY.SHOPIFY.CONFIGURE_PAGE.SAVE_BUTTON,
      variant: 'secondary',
      isLoading: formState?.isLoading,
      isDisabled: !formState?.isDirty || formState.isSubmitSuccessful,
    });
  }

  return (
    <Box padding={[6]}>
      {isLoading ? (
        <ShopifySkeleton />
      ) : (
        <>
          <PageHeader
            badgeProps={{
              label: isInstalled
                ? DICTIONARY.SHOPIFY.PAGE_HEADER.CONNECTED_BADGE_LABEL
                : DICTIONARY.SHOPIFY.PAGE_HEADER.NOT_CONNECTED_BADGE_LABEL,
              status: isInstalled ? 'success' : 'default',
            }}
            h1Text={DICTIONARY.SHOPIFY.PAGE_HEADER.H1_TEXT}
            h2Text={DICTIONARY.SHOPIFY.PAGE_HEADER.H2_TEXT}
            id='page-header'
            imageProps={{
              alt: DICTIONARY.SHOPIFY.PAGE_HEADER.LOGO_ALT,
              dataTestid: 'shopify-logo',
              id: 'integration-logo',
              src: 'https://trackstarlogosbucket.s3.amazonaws.com/shopify.png',
            }}
            primaryButtons={primaryButtons}
          />
          <Box padding={{ top: 4 }}>
            {isInstalled ? (
              <ShopifyStore ref={formRef} onFormStateChange={onFormStateChange} />
            ) : (
              <ShopifyInstallationForm />
            )}
          </Box>

          <Box padding={{ top: 6 }}>
            <ShopifyPermissions />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ShopifyInstallation;
