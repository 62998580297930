import { Link } from 'ds4-beta';
import React, {
  memo,
  useState,
  useMemo,
  useCallback,
  useLayoutEffect,
} from 'react';
import { DICTIONARY } from '../dictionary';

export interface CollapsiblePanelProps<T> {
  readonly id: string;
  readonly header: React.ReactNode;
  readonly items: T[];
  readonly renderItem: (item: T, index: number) => React.ReactNode;
  readonly isLoading?: boolean;
  readonly skeletonCount?: number;
  readonly renderSkeleton?: (index: number) => React.ReactNode;
  readonly defaultVisible?: number;
  readonly containerStyle?: React.CSSProperties;
  readonly gridStyle?: React.CSSProperties;
}

const DEFAULT_VISIBLE = 3;

function CollapsiblePanel<T>({
  id,
  header,
  items,
  renderItem,
  isLoading = false,
  skeletonCount = DEFAULT_VISIBLE,
  renderSkeleton = () => null,
  defaultVisible = DEFAULT_VISIBLE,
  containerStyle,
  gridStyle,
}: CollapsiblePanelProps<T>) {
  const [visible, setVisible] = useState<number>(defaultVisible);
  const [scrollTargetIdx, setScrollTargetIdx] = useState<number | null>(null);

  const handleShowMore = useCallback(() => {
    const newVisible = Math.min(visible + defaultVisible * 2, items.length);
    // store the target index to scroll to after render
    setScrollTargetIdx(visible);
    setVisible(newVisible);
  }, [visible, defaultVisible, items.length]);

  const handleShowLess = useCallback(() => {
    // scroll to the top element after reset
    setScrollTargetIdx(0);
    setVisible(defaultVisible);
  }, [defaultVisible]);

  useLayoutEffect(() => {
    if (scrollTargetIdx !== null) {
      const target = document.getElementById(
        `collapsible-${id}-${scrollTargetIdx}`
      );
      target?.scrollIntoView({ behavior: 'smooth' });
      // reset the target index after scrolling
      setScrollTargetIdx(null);
    }
  }, [visible, scrollTargetIdx, id]);

  const itemsToShow = useMemo(() => items.slice(0, visible), [items, visible]);
  const shouldShowToggle = items.length > defaultVisible;

  return (
    <div style={{ marginTop: '24px', ...containerStyle }}>
      {header}
      <div
        style={{
          ...(gridStyle || {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '16px',
            marginTop: '16px',
          }),
        }}
      >
        {isLoading
          ? Array.from({ length: skeletonCount }).map((_, index) =>
              renderSkeleton(index)
            )
          : itemsToShow.map((item, i) => (
            <div key={id} id={`collapsible-${id}-${i}`} className='collapsible-item'>
              {renderItem(item, i)}
            </div>
            ))}
      </div>
      {shouldShowToggle && !isLoading && (
        <div style={{ textAlign: 'center', marginTop: '1rem' }}>
          <Link
            label={
              items.length > visible
                ? DICTIONARY.GENERAL.BUTTONS.SHOW_MORE
                : DICTIONARY.GENERAL.BUTTONS.SHOW_LESS
            }
            mode='standalone'
            onClick={visible < items.length ? handleShowMore : handleShowLess}
            theme='light'
            variant='secondary'
          />
        </div>
      )}
    </div>
  );
}

export default memo(CollapsiblePanel);
