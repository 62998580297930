import {
  AdvancedSearchInput,
  Box,
  Button,
  Card,
  Link,
  theme,
  useToast,
} from 'ds4-beta';
import { useForm } from 'react-hook-form';
import { SHOPIFY_HELP_URL, SHOPIFY_KEY_MIN_LENGTH } from '../../../constants';
import { extractShopName } from '../utils';
import { DICTIONARY } from '../../../dictionary';
import { useInstallShopifyMutation } from '../mutations';

const ShopifyInstallationForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });

  const mutation = useInstallShopifyMutation();

  const toast = useToast();

  const onSubmit = async data => {
    await mutation
      .mutateAsync({
        shopName: data.shopifyStoreUrl,
        accessToken: data.apiKey,
        secretKey: data.apiSecretKey,
      })
      .then(() => {
        toast({
          id: 'shopify-installation-success',
          variant: 'default',
          label: DICTIONARY.SHOPIFY.INSTALLATION.SUCCESS_MESSAGE,
        });
      })
      .catch(() => {
        toast({
          id: 'shopify-installation-error',
          variant: 'error',
          label: DICTIONARY.SHOPIFY.INSTALLATION.ERROR_MESSAGE,
        });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card
        body={
          <div style={{ padding: '0 16px' }}>
            <AdvancedSearchInput
              label={DICTIONARY.SHOPIFY.INSTALLATION.STORE_URL.LABEL}
              required={true}
              inputProps={{
                dataTestid: DICTIONARY.SHOPIFY.TEST_IDS.STORE_URL,
                value: getValues('shopifyStoreUrl'),
                ...register('shopifyStoreUrl', {
                  required: {
                    value: true,
                    message:
                      DICTIONARY.SHOPIFY.INSTALLATION.STORE_URL.ERROR_REQUIRED,
                  },
                }),
                onChange: e => {
                  const inputValue = e.target.value.trim();
                  const shopName = extractShopName(inputValue);
                  setValue('shopifyStoreUrl', shopName);
                  trigger('shopifyStoreUrl');
                },
              }}
              suffix={DICTIONARY.SHOPIFY.INSTALLATION.STORE_URL.SUFFIX}
              messageType={errors.shopifyStoreUrl ? 'error' : undefined}
              message={errors.shopifyStoreUrl?.message?.toString() ?? ''}
            />

            <Box padding={{ top: 4 }}>
              <AdvancedSearchInput
                label={DICTIONARY.SHOPIFY.INSTALLATION.API_KEY.LABEL}
                required={true}
                inputProps={{
                  dataTestid: DICTIONARY.SHOPIFY.TEST_IDS.API_KEY,
                  ...register('apiKey', {
                    required: {
                      message:
                        DICTIONARY.SHOPIFY.INSTALLATION.API_KEY.ERROR_REQUIRED,
                      value: true,
                    },
                  }),
                }}
                tooltipInfo={{
                  text: DICTIONARY.SHOPIFY.INSTALLATION.API_KEY.TOOLTIP,
                }}
                messageType={errors.apiKey ? 'error' : undefined}
                message={errors.apiKey?.message?.toString() ?? ''}
              />
            </Box>

            <Box padding={{ top: 4 }}>
              <AdvancedSearchInput
                label={DICTIONARY.SHOPIFY.INSTALLATION.API_SECRET_KEY.LABEL}
                required={true}
                inputProps={{
                  dataTestid: DICTIONARY.SHOPIFY.TEST_IDS.API_SECRET,
                  ...register('apiSecretKey', {
                    required: true,
                    minLength: {
                      value: SHOPIFY_KEY_MIN_LENGTH,
                      message:
                        DICTIONARY.SHOPIFY.INSTALLATION.API_SECRET_KEY
                          .ERROR_REQUIRED,
                    },
                  }),
                }}
                messageType={errors.apiSecretKey ? 'error' : undefined}
                message={errors.apiSecretKey?.message?.toString() ?? ''}
              />
            </Box>

            <Box padding={{ top: 4, bottom: 4 }}>
              <Link
                icon='Import'
                label={
                  DICTIONARY.SHOPIFY.INSTALLATION.DOWNLOAD_INSTRUCTIONS.LABEL
                }
                mode='standalone'
                variant='primary'
                href={SHOPIFY_HELP_URL}
                onClick={e => {
                  e.preventDefault();
                  window.open(SHOPIFY_HELP_URL, '_blank');
                }}
              />
            </Box>
          </div>
        }
        open={true}
        showCollapse={false}
        customBodyPadding='0'
        header={
          <>
            <h2 style={theme.typography.h5}>
              {DICTIONARY.SHOPIFY.ADD_STORE_HEADER}
            </h2>
            <Button
              dataTestid={DICTIONARY.SHOPIFY.TEST_IDS.CONNECT_BUTTON}
              text={DICTIONARY.SHOPIFY.CONNECT_BUTTON_TEXT}
              variant='secondary'
              type='submit'
              isLoading={mutation.isLoading}
            />
          </>
        }
      />
    </form>
  );
};

export default ShopifyInstallationForm;
