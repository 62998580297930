import { theme } from 'ds4-beta';
import styled from 'styled-components';

export const Banner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  padding-left: 16px;
  background-color: ${theme.color.red['100']};
`;
