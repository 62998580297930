import { useEffect, useState } from 'react';
import { useTrackstarLink } from '@trackstar/react-trackstar-link';
import { CombinedConnector } from '../../types';
import { SelectableCard, Skeleton } from 'ds4-beta';
import { getTrackstarToken } from '../requests';

const TrackstarCard = ({
  id,
  trackstarId,
  name,
  description,
  imageUrl,
}: CombinedConnector) => {
  const [trackstarReady, setTrackstarReady] = useState<boolean>(
    typeof window !== 'undefined' && window.Trackstar !== undefined
  );

  const trackstar = useTrackstarLink({
    getLinkToken: getTrackstarToken,
    onSuccess: () => {},
    integrationAllowList: [trackstarId],
    buttonId: trackstarId,
  });

  useEffect(() => {
    if (!trackstarReady) {
      const interval = setInterval(() => {
        if (window.Trackstar !== undefined) {
          setTrackstarReady(true);
          clearInterval(interval);
        }
      }, 100);
      return () => clearInterval(interval);
    }
  }, [trackstarReady]);

  const handleActivation = () => trackstar.open({ integrationId: trackstarId });

  if (!trackstarReady) {
    return <Skeleton key={id} height='88px' width='100%' />;
  }

  return (
    <SelectableCard
      key={id}
      text={name}
      description={description}
      imageUrl={imageUrl}
      orientation='horizontal'
      selected={false}
      assetType='image'
      width='100%'
      onChange={handleActivation}
    />
  );
};

export default TrackstarCard;
