import { useEffect, useState } from 'react';

export const useBlockNavigation = (shouldBlock: boolean) => {
  const [isVisible, setIsVisible] = useState(false);
  const [proceedNavigation, setProceedNavigation] = useState(false);

  const handleBeforeUnload = (evt: BeforeUnloadEvent) => {
    if (shouldBlock && !proceedNavigation) {
      evt.preventDefault();
      setIsVisible(true);
    }
  };

  const proceed = () => {
    setProceedNavigation(true);
    setIsVisible(false);
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };

  const reset = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldBlock, proceedNavigation]);

  return {
    isVisible,
    proceed,
    reset,
  };
};
