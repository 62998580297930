import { Box, Icon, Modal, theme, useToast } from 'ds4-beta';
import { useUninstallShopifyMutation } from '../../mutations';
import { DICTIONARY } from '../../../../dictionary';
import { Banner } from './style';

const ShopifyUninstall = ({ isVisible, onClose }) => {
  const { isLoading, mutateAsync } = useUninstallShopifyMutation();
  const showToast = useToast();

  const handleUninstall = () => {
    if (isLoading) return;
    mutateAsync()
      .then(() => {
        showToast({
          label: DICTIONARY.SHOPIFY.UNINSTALL.DISCONNECTED_SUCCESSFULLY,
          id: 'shopify-store-disconnected-toast',
        });
      })
      .catch(error => {
        showToast({
          id: 'shopify-uninstall-error-toast',
          label: error.message,
          variant: 'error',
        });
      });
  };

  return (
    <Modal
      isVisible={isVisible}
      size='small'
      onClose={onClose}
      onBackdropClick={onClose}
      headerText={DICTIONARY.SHOPIFY.UNINSTALL.TITLE}
      footerButtons={[
        {
          text: DICTIONARY.SHOPIFY.UNINSTALL.CANCEL,
          onClick: onClose,
          variant: 'secondary',
          dataTestid: 'cancel',
        },
        {
          isLoading: isLoading,
          text: DICTIONARY.SHOPIFY.UNINSTALL.CONFIRM,
          onClick: handleUninstall,
          variant: 'destructive',
          dataTestid: 'confirm',
        },
      ]}
    >
      <Box>
        <Banner>
          <Icon
            iconName='Error'
            // @ts-ignore: copilot ds4 doesn't export the fill variant, so we need to use the string value
            variant='fill'
            color={theme.color.red['800']}
          />
          <p
            style={{
              ...theme.typography.body1.regular,
              color: theme.color.red['800'],
            }}
          >
            {DICTIONARY.SHOPIFY.UNINSTALL.BANNER_TEXT}
          </p>
        </Banner>
        <p
          style={{
            ...theme.typography.body1.regular,
            color: theme.color.grey['600'],
          }}
        >
          {DICTIONARY.SHOPIFY.UNINSTALL.DESCRIPTION}
        </p>
      </Box>
    </Modal>
  );
};

export default ShopifyUninstall;
