import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import ConnectorsList from './connectors';
import ShopifyInstallation from './installation/shopify';

const BASE_URL_PATH = '/integrations';

const PAGE_URLS = {
  COPILOT: {
    get HOME() {
      return '/';
    },
  },
  INTEGRATIONS: {
    get CONNECTORSLIST() {
      return BASE_URL_PATH;
    },
  },
  CONNECTORS_INSTALLATION: {
    get SHOPIFY() {
      return `${BASE_URL_PATH}/shopify`;
    },
  },
  SETTINGS: {
    get HOME() {
      return '/settings';
    },
    get DEVELOPER_TOOLS() {
      return '/settings/developer-tools';
    },
  },
};

const Redirect = ({ to }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to);
  }, []);

  return <></>;
};

const IntegrationRoutes = () => {
  return (
    <BrowserRouter>
      <main className='app_wrapper' id='app_root'>
        <Routes>
          <>
            <Route
              element={<Redirect to={PAGE_URLS.INTEGRATIONS.CONNECTORSLIST} />}
              path='/'
            />
            <Route
              element={<ConnectorsList />}
              path={PAGE_URLS.INTEGRATIONS.CONNECTORSLIST}
            />
            <Route
              element={<ShopifyInstallation />}
              path={PAGE_URLS.CONNECTORS_INSTALLATION.SHOPIFY}
            />
          </>
        </Routes>
      </main>
    </BrowserRouter>
  );
};

export { IntegrationRoutes };
