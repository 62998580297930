import { Box, Card, theme } from 'ds4-beta';
import { DICTIONARY } from '../../../dictionary';

const ShopifyPermissions = () => {
  return (
    <Card
      header={
        <h4
          style={{
            ...theme.typography.body1.regular,
            margin: '-16px',
            padding: '16px',
          }}
        >
          {DICTIONARY.SHOPIFY.PERMISSIONS_HEADER}
        </h4>
      }
      open={true}
      showCollapse={false}
      customBodyPadding='0'
      body={DICTIONARY.SHOPIFY.PERMISSIONS_REQUIRED.map(section => (
        <Box padding={[2, 2]} key={section.sectionTitle}>
          <Card
            key={section.sectionTitle}
            customBodyPadding='0'
            showDivider
            showCollapse
            header={
              <div style={{ margin: '-16px', padding: '0 16px' }}>
                <h5 style={{ ...theme.typography.h5, margin: '12px 0 0 0' }}>
                  {section.sectionTitle}
                </h5>
                <h6
                  style={{
                    ...theme.typography.body2.regular,
                    margin: '6px 0 12px 0',
                  }}
                >
                  {section.sectionSubtitle}
                </h6>
              </div>
            }
            body={section.permissions.map(permission => (
              <Box key={permission.title} padding={{ left: 4, right: 4, top: 3, bottom: 2 }}>
                <p
                  style={{
                    ...theme.typography.body1.regular,
                    color: theme.color.grey['700'],
                    margin: 0,
                  }}
                >
                  {permission.title}
                </p>
                {permission.content.split('\n').map((content) => (
                  <p key={content} style={{ ...theme.typography.body1.regular, margin: 0 }}>
                    {content}
                  </p>
                ))}
              </Box>
            ))}
          />
        </Box>
      ))}
    />
  );
};

export default ShopifyPermissions;