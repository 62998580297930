import { Skeleton, SkeletonBadge, SkeletonH5, SkeletonImage } from 'ds4-beta';
import { memo } from 'react';

const ShopifySkeleton = () => {
  return (
    <div data-testid='skeleton-loader' style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
      <div style={{ display: 'flex', gap: 10, flexDirection: 'row' }}>
        <SkeletonImage size='medium' />
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <SkeletonH5 width='100px' />
          <SkeletonH5 width='300px' />
          <SkeletonBadge />
        </div>
      </div>

      <Skeleton width='100%' height='200px' />
      <Skeleton width='100%' height='200px' />
    </div>
  );
};

export default memo(ShopifySkeleton);
