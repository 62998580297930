import { useQueries, UseQueryOptions } from 'react-query';
import { client } from '../lib/request';
import { Connector, ConnectorConfiguration } from '../types';
import trackstar from '../assets/trackstar.json';
import { getInstalledConnectors } from '../common/requests';
import { DICTIONARY } from '../dictionary';

const CONNECTED = 'CONNECTED';

export const connectors = {
  queryKey: [DICTIONARY.QUERY_KEYS.CONNECTORS],
  queryFn: () =>
    client<Error, { data: Connector[] }>({
      url: '/connectors',
      method: 'GET',
    }),
  select: data => data.data,
};

export const trackstarIntegrations = {
  queryKey: [DICTIONARY.QUERY_KEYS.TRACKSTAR_INTEGRATIONS],
  queryFn: () => trackstar,
};

export const installedConnectors: UseQueryOptions = {
  queryKey: [DICTIONARY.QUERY_KEYS.INSTALLED_CONNECTORS],
  queryFn: getInstalledConnectors,
  select: (data: ConnectorConfiguration[]) =>
    data.map(connector => ({
      ...connector.connector,
      domain: CONNECTED,
      iconUrl: connector.configurations.shopName ? 'https://trackstarlogosbucket.s3.amazonaws.com/shopify.png' : null,
      description: connector.configurations.shopName ? `${connector.configurations.shopName}.myshopify.com` : null,
    })),
};

export const useConnectors = () => {
  const queries = useQueries([
    installedConnectors,
    connectors,
    trackstarIntegrations,
  ]);
  const isLoading = queries.some(({ isLoading }) => isLoading);

  const results = queries.flatMap(({ data }) => data ?? []);
  const installed = queries[0].data as ConnectorConfiguration[];

  const filteredResults = results.filter(result => {
    return (
      (!installed?.some(installed => installed.id === result.id) &&
        result.domain != CONNECTED) ||
      result.domain == CONNECTED
    );
  });

  const data = filteredResults.map(connector => ({
    name: connector.title ?? connector.name,
    description: connector.description ?? '...',
    imageUrl: connector.imageUrl ?? connector.iconUrl,
    trackstarId: connector.link != null ? connector.id : undefined,
    ...connector,
  }));

  return { isLoading, data };
};
