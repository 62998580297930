import { Banner, Box, Card, Modal, Switch, useToast, Dropdown } from 'ds4-beta';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { DICTIONARY } from '../../../../dictionary';
import { useShopifyInstalledConnector } from '../../queries';
import { Controller, useForm } from 'react-hook-form';
import { useBlockNavigation } from '../../hooks/useBlockNavigation';
import { useStartJobShopifyMutation } from '../../mutations';
import { Option, ShopifyStoreProps } from '../../types';
import {
  LeavePageDescription,
  OptionContainer,
  OptionDescription,
  OptionTitle,
} from './style';
import { Header } from './header';

const OPTIONS = DICTIONARY.SHOPIFY.SHOPIFY_IMPORT_OPTIONS as unknown as Option[];

const ShopifyStore = forwardRef(
  ({ onFormStateChange }: ShopifyStoreProps, ref) => {
    const {
      handleSubmit,
      setValue,
      trigger,
      formState: { isDirty, isSubmitSuccessful, isLoading },
      control,
    } = useForm({
      mode: 'onBlur',
      defaultValues: {
        locations: false,
        products: false,
        inventory: false,
        orderHistory: false,
        orderHistoryRange: '30',
      },
    });

    const { data: shopify } = useShopifyInstalledConnector();
    const { mutateAsync } = useStartJobShopifyMutation();
    const toast = useToast();

    const onSubmit = async values => {
      try {
        await mutateAsync({
          shopName: shopify.configurations.shopName,
          inventories: {
            shouldImport: values.inventory,
            lastDays: 0,
            lastHours: 0,
          },
          locations: {
            shouldImport: values.locations,
            lastDays: 0,
            lastHours: 0,
          },
          orders: {
            shouldImport: values.orderHistory,
            lastDays: parseInt(values.orderHistoryRange),
            lastHours: 0,
          },
          products: {
            shouldImport: values.products,
            lastDays: 0,
            lastHours: 0,
          },
        });
        toast({
          label: DICTIONARY.SHOPIFY.CONFIGURE_PAGE.SYNC_SETTINGS_SAVED,
          id: 'shopify-store-sync-settings-saved',
        });
      } catch (e) {
        toast({
          label: DICTIONARY.SHOPIFY.CONFIGURE_PAGE.SYNC_SETTINGS_FAILED,
          id: 'shopify-store-sync-settings-failed',
          variant: 'error',
        });
      }
    };

    useEffect(() => {
      onFormStateChange({ isDirty, isLoading, isSubmitSuccessful });
    }, [isDirty, isLoading, isSubmitSuccessful]);

    useImperativeHandle(ref, () => ({
      submit: () => {
        handleSubmit(onSubmit)();
      },
    }));

    const { isVisible, proceed, reset } = useBlockNavigation(isDirty);

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          header={<Header shopify={shopify} />}
          showCollapse={!isSubmitSuccessful}
          open={!isSubmitSuccessful}
          customBodyPadding='0'
          body={
            <Box padding={{ top: 2, bottom: 4, left: 4, right: 4 }}>
              <Banner
                isDismissable={false}
                label={DICTIONARY.SHOPIFY.CONFIGURE_PAGE.BANNER_SYNC_MESSAGE}
                variant='feedback'
              />
              {OPTIONS.map(option => (
                <OptionContainer key={option.id}>
                  <div>
                    <OptionTitle>{option.title}</OptionTitle>
                    <OptionDescription>{option.description}</OptionDescription>
                    {option.select && (
                      <Controller
                        name={option.select.id}
                        control={control}
                        render={({ field }) => (
                          <Dropdown
                            dataTestid={option.select.id}
                            onChange={_option => {
                              field.onChange(_option.id);
                              trigger(option.select.id);
                              setValue(option.id, true);
                            }}
                            value={option.select.options.find(
                              _option => _option.id === field.value
                            )}
                            label={option.select.label}
                            variant='large'
                            options={option.select.options}
                          />
                        )}
                      />
                    )}
                  </div>
                  <Controller
                    name={option.id}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Switch
                        id={option.id}
                        dataTestid={option.id}
                        label=''
                        checked={field.value}
                        variant='default'
                        onChange={e => {
                          field.onChange(e.target.checked);
                          trigger(option.id);
                        }}
                      />
                    )}
                  />
                </OptionContainer>
              ))}
            </Box>
          }
        />
        <Modal
          isVisible={isVisible}
          headerText={
            DICTIONARY.SHOPIFY.CONFIGURE_PAGE.LEAVE_PAGE_CONFIRMATION.TITLE
          }
          size='small'
          footerButtons={[
            {
              text: DICTIONARY.SHOPIFY.CONFIGURE_PAGE.LEAVE_PAGE_CONFIRMATION
                .RESET,
              variant: 'tertiary',
              onClick: () => reset(),
            },
            {
              text: DICTIONARY.SHOPIFY.CONFIGURE_PAGE.LEAVE_PAGE_CONFIRMATION
                .PROCEED,
              variant: 'destructive',
              onClick: () => proceed(),
            },
          ]}
        >
          <LeavePageDescription>
            {
              DICTIONARY.SHOPIFY.CONFIGURE_PAGE.LEAVE_PAGE_CONFIRMATION
                .DESCRIPTION
            }
          </LeavePageDescription>
        </Modal>
      </form>
    );
  }
);

export default ShopifyStore;
