import { theme } from "ds4-beta";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ShopName = styled.h4`
  ${theme.typography.h5};
  margin: -16px;
  padding: 16px;
`;

export const MoreActionsButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid ${theme.color.grey[200]};
  margin-top: 12px;
  padding-top: 12px;
`;

export const OptionTitle = styled.p`
  ${theme.typography.body1.medium};
  margin: 0;
`;

export const OptionDescription = styled.p`
  ${theme.typography.body2.regular};
  max-width: 90%;
`;

export const DisconnectLabel = styled.label`
  ${theme.typography.body1.regular};
  margin-left: 6px;
  cursor: pointer;
  color: ${theme.color.grey[1000]};
`;

export const LeavePageDescription = styled.p`
  ${theme.typography.body1.regular};
`;