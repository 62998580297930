import { AxiosError } from 'axios';
import { useToast } from 'ds4-beta';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AXIOS_ERRORS } from '../constants';
import { useMemo } from 'react';

const MIN_STALE_TIME = 5; // minutes

const queryClient = showToast =>
  new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        staleTime: MIN_STALE_TIME * 60 * 1000,
        onError: (error: AxiosError) => {
          // Future improvements:
          // - parse error messages into readable
          // - double check if we really want to show a toast
          console.error('Error during API call', error);

          // Do not show error toast if it was a Bad Request error
          if (error.code === AXIOS_ERRORS.ERR_BAD_REQUEST) {
            return;
          }

          showToast({ id: 'query-error-toast', label: error.message });
        },
      },
    },
  });

const QueryProvider = ({ children }) => {
  const showToast = useToast();

  // store the queryClient in a useMemo to avoid creating a new instance on every render
  const client = useMemo(() => queryClient(showToast), []);

  return (
    <QueryClientProvider client={client}>
      {children}
    </QueryClientProvider>
  );
};

const jestQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const JestQueryProvider = ({ children }) => {
  return (
    <QueryClientProvider client={jestQueryClient}>
      {children}
    </QueryClientProvider>
  );
};

export { QueryProvider, JestQueryProvider };
