/** Dictionary of terms and labels used across the app */

export const DICTIONARY = {
  INTEGRATIONS_HEADER: {
    TITLE_HEADER: 'Integrations',
    MORE_INFO_HEADER: 'Enhance your Fabric experience with integrations',
    SEARCH_PLACEHOLDER: 'Search by Integration name',
  },
  FILTER: {
    CATEGORY: 'Category',
    RESET_ALL: 'Reset All',
  },
  GENERAL: {
    BUTTONS: {
      SHOW_LESS: 'Show Less',
      SHOW_MORE: 'Show More',
    },
  },

  SHOPIFY: {
    INSTALLATION: {
      STORE_URL: {
        LABEL: 'Shopify Store URL',
        ERROR_REQUIRED: 'Please provide a Shopify Store URL',
        SUFFIX: '.myshopify.com',
      },
      API_KEY: {
        LABEL: 'Access Token',
        ERROR_REQUIRED: 'Please provide a valid Admin API access token',
        TOOLTIP: 'Your Shopify Admin API access token',
      },
      API_SECRET_KEY: {
        LABEL: 'API Secret Key',
        ERROR_REQUIRED: 'Please provide a valid API secret key',
      },
      DOWNLOAD_INSTRUCTIONS: {
        LABEL: 'Download Instructions for Obtaining Your Shopify API Key',
      },
      SUCCESS_MESSAGE:
        'Shopify store integration connected successfully! Configure and save sync settings to start data sync',
      ERROR_MESSAGE: 'Failed to connect to Shopify store. Please try again',
    },
    PAGE_HEADER: {
      CONNECTED_BADGE_LABEL: 'Connected',
      NOT_CONNECTED_BADGE_LABEL: 'Not connected',
      H1_TEXT: 'Shopify',
      H2_TEXT: 'Extend your order capabilities with fabric Commerce',
      LOGO_ALT: 'Shopify Logo',
      CLOSE_BUTTON_TEXT: 'Close',
    },
    ADD_STORE_HEADER: 'Add Shopify Store',
    CONNECT_BUTTON_TEXT: 'Connect',
    PERMISSIONS_HEADER:
      'What this integration can access in your Shopify store:',
    PERMISSIONS_REQUIRED: [
      {
        sectionTitle: 'View personal data',
        sectionSubtitle: 'Customers',
        permissions: [
          {
            title: 'View customer data',
            content:
              'Customers: Name, email address, phone number, physical address, and associated metadata.',
          },
        ],
      },
      {
        sectionTitle: 'View and edit store data',
        sectionSubtitle:
          'Orders, Fulfillments, Products & Inventory, Shipping & Returns, Locations, Markets, Channels, Locales',
        permissions: [
          {
            title: 'Manage orders and fulfillments',
            content:
              'View and manage orders, including creating and editing orders and order edits.\nView and manage fulfillments, including merchant-managed and third-party fulfillment orders, and set fulfillment constraint rules.',
          },
          {
            title: 'View and manage products and inventory',
            content:
              'View and edit products, product listings, and inventory data.',
          },
          {
            title: 'Manage shipping and returns',
            content: 'View and manage shipping rates and returns policies.',
          },
          {
            title: 'Manage store locations',
            content:
              'View and manage locations for inventory and fulfillment purposes.',
          },
          {
            title: 'Manage store markets and channels',
            content:
              'View and edit store markets, including regional settings and configurations.\nView and manage online and offline sales channels.',
          },
          {
            title: 'Manage store locales',
            content:
              'View and update supported store locales for translations and region-specific settings.',
          },
        ],
      },
    ],
    TEST_IDS: {
      STORE_URL: 'store-url',
      API_KEY: 'api-key',
      API_SECRET: 'api-secret',
      CONNECT_BUTTON: 'connect-button',
      SAVE_BUTTON: 'save-button',
      MORE_ACTIONS: 'more-actions',
    },

    CONFIGURE_PAGE: {
      DISCONECT: 'Disconnect',
      BANNER_SYNC_MESSAGE:
        'Configure and save sync settings to start data sync',

      LEAVE_PAGE_CONFIRMATION: {
        TITLE: 'Leave page without saving?',
        DESCRIPTION:
          "You haven't finished saving your changes. Your changes will be lost if you leave. Do you want to leave without saving?",
        RESET: 'No, stay on the page',
        PROCEED: 'Yes, leave now',
      },

      SAVE_BUTTON: 'Save',
      SYNC_SETTINGS_SAVED:
        'Shopify store sync settings saved! Sync will now run in the background',
      SYNC_SETTINGS_FAILED: 'Failed to save Shopify store sync settings',
    },

    SHOPIFY_IMPORT_OPTIONS: [
      {
        id: 'locations',
        title: 'Import Locations',
        description:
          "When enabled, locations from your Shopify store will sync automatically into fabric and be viewable in the 'Locations' page of Copilot.",
      },
      {
        id: 'products',
        title: 'Import Products',
        description:
          "When enabled, products from your Shopify store will sync automatically into fabric and be viewable in the 'Products' page of Catalog Connector app in Copilot.",
      },
      {
        id: 'inventory',
        title: 'Import Inventory',
        description:
          "When enabled, inventory from your Shopify store will sync automatically into Fabric and be viewable on the 'Inventory' page of Copilot.",
      },
      {
        id: 'orderHistory',
        title: 'Import Order History',
        description:
          'When enabled, past orders within the specified date range within your Shopify store will sync automatically with fabric and be viewable in the "Orders" page of Copilot.',
        select: {
          id: 'orderHistoryRange',
          label: 'Order Sync Range',
          options: [
            {
              id: '30',
              label: 'Last 30 days',
            },
            {
              id: '60',
              label: 'Last 60 days',
            },
            {
              id: '90',
              label: 'Last 90 days',
            },
          ],
        },
      },
    ],

    SHOPIFY_SELECT_OPTIONS_ID: ['orderHistoryRange'],

    UNINSTALL: {
      TITLE: 'Are you sure you want to disconnect?',
      BANNER_TEXT: "You can't undo this action",
      DESCRIPTION:
        'Disconnecting this Shopify store will remove it from your list of integrations. Any data already synced between fabric and your Shopify store will remain intact, but all future sync operations will cease.',
      CANCEL: "No, Don't Disconnect",
      CONFIRM: 'Yes, Disconnect',
      DISCONNECTED_SUCCESSFULLY: 'Shopify store disconnected successfully',
    }
  },
  MUTATION_KEYS: {
    INSTALL_SHOPIFY: 'install-shopify',
    START_JOB_SHOPIFY: 'start-job-shopify',
    UNINSTALL_SHOPIFY: 'uninstall-shopify',
  },
  QUERY_KEYS: {
    CONNECTORS: 'connectors',
    INSTALLED_CONNECTORS: 'installed-connectors',
    TRACKSTAR_INTEGRATIONS: 'trackstar-integrations',
  },
} as const;
