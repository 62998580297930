export const extractShopName = (url: string) => {
  const regex = /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]{1,63})\.myshopify\.com/;
  const match = regex.exec(url);
  return match ? match[1] : '';
};

export const buildImportConfig = (
  configs: Record<
    string,
    { shouldImport: boolean; lastDays: number; lastHours: number }
  >
) => {
  const config: Record<string, { last_days: number; last_hours: number }> = {};
  Object.entries(configs).forEach(([key, configItem]) => {
    if (configItem.shouldImport) {
      config[key] = {
        last_days: configItem.lastDays,
        last_hours: configItem.lastHours,
      };
    }
  });
  return config;
};
