// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#app_root #connectors-list img {
  mix-blend-mode: multiply;
  width: 32px !important;
  height: auto;
}

.collapsible-item:nth-child(n+7) {
  opacity: 0;
  animation: showCard 1s forwards;
}

@keyframes showCard {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/connectors/style.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,UAAU;EACV,+BAA+B;AACjC;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":["#app_root #connectors-list img {\n  mix-blend-mode: multiply;\n  width: 32px !important;\n  height: auto;\n}\n\n.collapsible-item:nth-child(n+7) {\n  opacity: 0;\n  animation: showCard 1s forwards;\n}\n\n@keyframes showCard {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
