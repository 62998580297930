import { Badge, Icon, Menu, MenuItem, MenuList } from 'ds4-beta';
import {
  DisconnectLabel,
  HeaderContainer,
  HeaderLeft,
  MoreActionsButton,
  ShopName,
} from './style';
import { DICTIONARY } from '../../../../dictionary';
import { useState } from 'react';
import ShopifyUninstall from '../ShopifyUninstall';

export const Header = ({ shopify }) => {
  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false);
  return (
    <>
      <HeaderContainer>
        <HeaderLeft>
          <ShopName>
            {`${shopify?.configurations?.shopName}.myshopify.com`}
          </ShopName>
          <Badge
            label={DICTIONARY.SHOPIFY.PAGE_HEADER.CONNECTED_BADGE_LABEL}
            variant='secondary'
            asset='bullet'
            status='success'
          />
        </HeaderLeft>
        <Menu
          trigger={
            <MoreActionsButton type='button' data-testid={DICTIONARY.SHOPIFY.TEST_IDS.MORE_ACTIONS}>
              <Icon iconName='MoreActions' />
            </MoreActionsButton>
          }
          placement='bottom-end'
        >
          <MenuList onItemSelect={() => setIsDisconnectModalOpen(true)}>
            <MenuItem
              value={{
                id: 'disconnect',
              }}
              style={{ cursor: 'pointer' }}
            >
              <Icon iconName='Close' />
              <DisconnectLabel>
                {DICTIONARY.SHOPIFY.CONFIGURE_PAGE.DISCONECT}
              </DisconnectLabel>
            </MenuItem>
          </MenuList>
        </Menu>
      </HeaderContainer>
      {isDisconnectModalOpen && <ShopifyUninstall isVisible={isDisconnectModalOpen} onClose={() => setIsDisconnectModalOpen(false)} />}
    </>
  );
};
