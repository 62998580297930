import { useQuery, UseQueryResult } from 'react-query';
import { installedConnectors } from '../../connectors/queries';
import { ConnectorConfiguration } from '../../types';

export const useShopifyInstalledConnector =
  (): UseQueryResult<ConnectorConfiguration> => {
    return useQuery({
      ...installedConnectors,
      select: (data: ConnectorConfiguration[]) => {
        return data.find(
          connector =>
            connector.connectorId === process.env.SHOPIFY_CONNECTOR_ID
        );
      },
    });
  };
