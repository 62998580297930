import { useMutation, useQueryClient } from 'react-query';
import { client } from '../../lib/request';
import { SessionUtils } from '../../lib/session';
import { buildImportConfig } from './utils';
import { DICTIONARY } from '../../dictionary';

type InstallShopifyParams = {
  shopName: string;
  accessToken: string;
  secretKey: string;
};

type StartJobShopifyParams = {
  shopName: string;
  products: {
    shouldImport: boolean;
    lastDays: number;
    lastHours: number;
  };
  inventories: {
    shouldImport: boolean;
    lastDays: number;
    lastHours: number;
  };
  locations: {
    shouldImport: boolean;
    lastDays: number;
    lastHours: number;
  };
  orders: {
    shouldImport: boolean;
    lastDays: number;
    lastHours: number;
  };
};

export const useInstallShopifyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: InstallShopifyParams) => {
      const { shopName, accessToken, secretKey } = params;
      return client({
        url: `/configurations/${process.env.SHOPIFY_CONNECTOR_ID}/install`,
        method: 'POST',
        data: {
          configurations: {
            shopName,
            accessToken,
            secretKey,
            triggerInitialSetup: 'false',
          },
          enabledActions: [],
        },
      });
    },
    {
      onSettled: () => {
        queryClient.refetchQueries({
          queryKey: [DICTIONARY.QUERY_KEYS.INSTALLED_CONNECTORS],
        });
      },
      mutationKey: [DICTIONARY.MUTATION_KEYS.INSTALL_SHOPIFY],
    }
  );
};

export const useStartJobShopifyMutation = () => {
  return useMutation(
    async (params: StartJobShopifyParams) => {
      const { shopName, products, inventories, locations, orders } = params;

      const config = buildImportConfig({
        products,
        inventories,
        locations,
        orders,
      });

      await client({
        url: `/shopify/job-orchestrator/admin/job-steps/start-job`,
        method: 'POST',
        data: {
          tenant_id: SessionUtils.getTenantId(),
          shop_name: shopName,
          job_type: 'INITIAL_OFFLOAD',
          import_config: {
            shopify_to_fabric: config,
          },
        },
      });
    },
    {
      mutationKey: [DICTIONARY.MUTATION_KEYS.START_JOB_SHOPIFY],
    }
  );
};

export const useUninstallShopifyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return client({
        url: `/configurations/${process.env.SHOPIFY_CONNECTOR_ID}/uninstall`,
        method: 'DELETE',
      });
    },
    {
      onSettled: () => {
        queryClient.refetchQueries({
          queryKey: [DICTIONARY.QUERY_KEYS.INSTALLED_CONNECTORS],
        });
      },
      mutationKey: [DICTIONARY.MUTATION_KEYS.UNINSTALL_SHOPIFY],
    }
  );
};
